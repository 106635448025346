import React from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import DataAid from '../../constants/dataAids';
import SubscribeForm from '../../form-components/subscribe-form';
import FormSuccessMessage from '../../form-components/form-success-message';
import InnerForm from '../../form-components/inner-form';

class SubscribeForm3 extends SubscribeForm {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      confirmationMessage,
      staticContent,
      category,
      section,
      background,
      couponConfirmationMessage,
      couponDiscount,
      couponDiscountMessage
    } = this.props;
    const { verificationText } = staticContent;
    const categoryValue = background && background.image ? 'accent' : category;
    const sectionValue = background && background.image ? 'overlay' : section;
    if (this.state.formSubmitted) {
      return (
        <UX2.Base category={ categoryValue } section={ sectionValue }>
          <FormSuccessMessage
            formSuccessMessage={
              this.shouldShowCoupon() ? couponConfirmationMessage : confirmationMessage
            }
            verificationText={ verificationText }
            olsConfigHost={ this.getOlsConfigHost() }
            showCoupon={ this.shouldShowCoupon() }
            couponDiscount={ couponDiscount }
            couponDiscountMessage={ couponDiscountMessage }
          />
        </UX2.Base>
      );
    }

    const Grid = UX2.Component.Grid;
    return (
      <UX2.Base category={ categoryValue } section={ sectionValue }>
        <Grid bottom={ false } inset={ true } data-aid={ DataAid.SUBSCRIBE_INNER_FORM_REND }>
          <Grid.Cell>
            { this.renderDescriptionSection() }
            <InnerForm
              formSubmitHost={ this.getFormSubmitHost() }
              onSubmit={ this.onSubmit }
              { ...this.props }
            />
          </Grid.Cell>
        </Grid>
      </UX2.Base>
    );
  }
}

export default SubscribeForm3;
